import React, {Dispatch, SetStateAction} from "react";
import {Modal, Button, Checkbox, Form, message} from "antd";
import Title from "antd/es/typography/Title";
import {useMutation, useQuery} from "@apollo/client";
import {createInvoice as createInvoiceQuery, Invoice} from "../../networking/api/invoice";
import {Client as ClientType, getClient} from "../../networking/api/client";
import {Copier as CopierType, getCopiers as getCopiersQuery} from "../../networking/api/copier";

const CreateInvoice = ({clientId, visible, setVisible}: { clientId: string, visible: boolean, setVisible: Dispatch<SetStateAction<boolean>> }) => {
  const {data: {client} = {client: null}} = useQuery<{ client: ClientType | null }>(getClient, {variables: {clientId}})
  const {data: {copiers} = {copiers: []}} = useQuery<{ copiers: CopierType[] }>(getCopiersQuery, {variables: {clientId}});
  const [createInvoice, {loading: creating}] = useMutation(createInvoiceQuery, {
    update(cache, {data: {createInvoice}}) {
      cache.modify({
        fields: {
          invoices(existingInvoices: Invoice[] = []) {
            return [...existingInvoices, createInvoice]
          }
        }
      });
      cache.evict({
        fieldName: "copiers"
      });
    }
  });

  if (!client) return null;

  const options = copiers.map(copier => ({
    label: copier.id,
    value: copier.id,
  }))
  options.sort((a, b) => a.value.localeCompare(b.value));

  const submit = (values: { copiersToInvoice: string[], dateRange: any[] }) => {
    const formattedData = {
      clientId: client._id,
      copiersToInvoice: values.copiersToInvoice
    }

    createInvoice({variables: {data: formattedData}}).then(_ => {
      message.success("Created Invoice")
      setVisible(false);
    }).catch(err => {
      console.log(err);
      message.error("Could not create invoice")
    })
  }

  return (
    <Modal
      title={"Invoice Client"}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form onFinish={submit}>
        <Title level={5}>Copiers To Invoice</Title>
        <Form.Item name={"copiersToInvoice"} initialValue={options.map(option => option.value)}>
          <Checkbox.Group
            style={{marginTop: 10}}
            options={options}
          />
        </Form.Item>

        <Title level={5} style={{marginTop: 20}}>Invoice Time Period</Title>

        <Button type="primary" htmlType={"submit"} style={{marginTop: 20}} disabled={creating}>
          Submit
        </Button>
      </Form>
    </Modal>)
}

export default CreateInvoice;
