import React from 'react';
import {Col} from "antd";
import HomeSearch from "./components/home-search";
import HomeCharts from "./components/home-charts";
import ClientList from "./components/client-list";


const Home = () => {
  return (
    <div className="home">
      <Col span={16} offset={4} style={{marginTop: 40}}>
        <HomeSearch/>
        <ClientList style={{marginTop: 40}}/>
        <HomeCharts/>
      </Col>
    </div>
  );
}

export default Home;
