import {Modal} from "antd";
import React, {Dispatch, SetStateAction} from "react";
import CreateCopierForm from "./create-copier-form";

type ModalProps = {
  clientId: string;
  mounted: boolean;
  setMounted: Dispatch<SetStateAction<any>>;
}

const CreateCopierModal = ({clientId, mounted, setMounted}: ModalProps) =>
  <Modal title={"Create a Copier"} visible={mounted} onCancel={() => setMounted(false)} footer={null}>
    <CreateCopierForm hideModal={() => {
      setMounted(false)
    }} clientId={clientId}/>
  </Modal>;

export default CreateCopierModal;