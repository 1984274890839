import {Button} from "antd";
import {PrinterOutlined, DeleteOutlined} from "@ant-design/icons";
import React from "react";
import {NativeButtonProps} from "antd/es/button/button";

export const PrintButton = (props: NativeButtonProps) => <Button type="primary" shape="circle" icon={<PrinterOutlined/>}
                                                                 size={"large"} {...props}/>
export const DeleteButton = (props: NativeButtonProps) => <Button type="primary" shape="circle" icon={<DeleteOutlined/>}
                                                                  size={"large"} color={""} {...props} style={{
  ...props.style,
  borderColor: "red",
  backgroundColor: "red"
}}/>

