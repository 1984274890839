import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import {getCookie} from "tiny-cookie";

const charts = new ChartsEmbedSDK({
  baseUrl: "https://charts.mongodb.com/charts-project-0-eyyqz", // ~REPLACE~ with the Base URL from your Embed Chart dialog
  getUserToken: () => {
    return getCookie('jwt') || "";
  }
})


export const readingsChart = () => charts.createChart({
  chartId: "ea3a34e6-7a9d-411d-a9b9-3c9c4b9a1465"
})

export const ratesChart = () => charts.createChart({
  chartId: "749c0def-10b6-4d8d-b84c-a0a6adc159ca"
})

export default charts;
