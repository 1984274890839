import {Copier as ICopier} from "../../../networking/api/copier";
import {Descriptions} from "antd";
import React from "react";
import Text from "antd/es/typography/Text";

export function uppercaseFirst(name: string) {
  return name.charAt(0).toUpperCase() + name.slice(1);
}

const DisplayCopier = ({copier}: { copier: ICopier }) => {
  const lastReading = copier.readings[copier.readings.length - 1];

  return (
    <Descriptions column={2} bordered style={{marginBottom: 20}}>
      <Descriptions.Item label={"ID"} span={2}>{copier.id}</Descriptions.Item>
      {copier.location ? <Descriptions.Item label={"Location"} span={2}>{copier.location}</Descriptions.Item> : null}
      <Descriptions.Item label={"Model"} span={2}>{copier.copierModel}</Descriptions.Item>
      <Descriptions.Item label={"PrintFleet Id"} span={2}>{copier.printFleetId || "N/A"}</Descriptions.Item>
      <Descriptions.Item label="Rental Price" span={2}>${copier.rentalPrice}</Descriptions.Item>
      {copier.rates.map(rate =>
        <Descriptions.Item key={rate.name} label={`${uppercaseFirst(rate.name)} Rate`}>${rate.value}</Descriptions.Item>
      )}
      <Descriptions.Item label="Last Reading" span={2}>
        {(lastReading) ? lastReading.data.map(reading =>
          <>
            <Text strong>{uppercaseFirst(reading.name)}</Text>: {reading.value}
            <br/>
          </>
        ) : "N/A"}
      </Descriptions.Item>
    </Descriptions>
  )
}
export default DisplayCopier;
