import axios from 'axios';


const url = {
  prod: "https://api.nafeesrawji.com:8444/auth",
  dev: "https://api.nafeesrawji.com:8444/auth"
};

export const AuthService = axios.create({
  baseURL: process.env.REACT_APP_STAGE === 'prod' ? url.prod : url.dev
});
