import React from 'react';
import {Button, Form, Input, message, Row} from "antd";
import Title from "antd/es/typography/Title";
import {createLocation as createLocationQuery, LocationInputData} from "../../../../networking/api/location";
import {useMutation} from "@apollo/client";

const CreateLocationForm = ({hideModal, clientId}: { hideModal: VoidFunction, clientId: string}) => {
  const [createLocation, {loading: creating}] = useMutation(createLocationQuery);
  const [form] = Form.useForm();
  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
  };

  const submit = (values: LocationInputData) => {
    values.clientId = clientId
    createLocation({variables: values}).then(() => {
      form.resetFields();
      message.success("Created Location")
      hideModal();
    }).catch(error => {
      console.log(error);
      message.success("Could Not Create Location")
    });
  };

  return (
    <Form {...layout} form={form} name="create-location" onFinish={(values: LocationInputData) => submit(values)}>
      <Title level={5}>Location Info</Title>
      <Form.Item label="Name" name="name" rules={[{required: true, message: "Client name is Required!"}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Area" name={"area"}
                 rules={[{required: true, message: "Address is Required!"}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Email" name="email">
        <Input/>
      </Form.Item>
      <Form.Item label="Phone Number" name="phoneNumber">
        <Input/>
      </Form.Item>
      <Title level={5}>Address</Title>
      <Form.Item label="Address" name={["address", "address"]}
                 rules={[{required: true, message: "Address is Required!"}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="City" name={["address", "city"]}
                 rules={[{required: true, message: "City is Required!"}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Province" name={["address", "province"]}
                 rules={[{required: true, message: "Province is Required!"}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Postal Code" name={["address", "postalCode"]}
                 rules={[{required: true, message: "Postal Code is Required!"}]}>
        <Input/>
      </Form.Item>
      <Row>
        <Button style={{margin: '0 8px'}} onClick={_ => form.resetFields()}>
          Clear
        </Button>
        <Button type="primary" htmlType="submit" disabled={creating}>
          Create
        </Button>
      </Row>
    </Form>
  );
}


export default CreateLocationForm;
