import {gql} from '@apollo/client';
import {Client} from "./client";
import {KeyValuePair, Reading} from "./meter-readings";
import {FullCopier} from "./fragments/copier";

export type Copier = {
  id: string;
  client?: Client
  location?: string;
  copierModel: string;
  clientId: string;
  rates: KeyValuePair[]
  readings: Reading[]
  rentalPrice: number;
  printFleetId?: string | null
}

export type CopierInputData = Omit<Copier, "readings" | "client">

export type EditCopierData = Omit<CopierInputData, "clientId">

export const createCopier = gql`
    mutation createCopier(
        $copierData: CreateCopierInput
    ) {
        createCopier(copierData: $copierData) {
            ...FullCopier
        }
    }
    ${FullCopier}
`;

export const getCopiers = gql`
    query getCopiers($clientId: String) {
        copiers(clientId: $clientId) {
            ...FullCopier
        }
    }
    ${FullCopier}
`;

export const copierSearch = gql`
    query copierSearch($query: String) {
        copierSearch(query: $query) {
            id
            client {
                _id
                name
            }
        }
    }
`;

export const editCopier = gql`
    mutation editCopier(
        $id: String!,
        $copierData: EditCopierInput!
    ) {
        editCopier(id: $id, copierData: $copierData) {
            ...FullCopier
        }
    }
    ${FullCopier}
`;

export const deleteCopier = gql`
    mutation deleteCopier($id: String!) {
        deleteCopier(id: $id)
    }
`;
