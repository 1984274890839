import React, {useState} from "react";
import {Copier as CopierType, deleteCopier as deleteCopierQuery} from "../../../networking/api/copier";
import {Client as ClientType} from "../../../networking/api/client";
import {message, Popconfirm, Row} from "antd";
import {StyledEditText} from "../../styled-antd";
import EditCopier from "./edit-copier";
import DisplayCopier from "./display-copier";
import {useMutation} from "@apollo/client";

enum CopierState {
  editing,
  meterReading,
  editMeterReading,
  displaying
}

export type OnCopierEdit = () => void

const Copier = ({copier, client}: { copier: CopierType, client: ClientType }) => {
  const [copierState, setCopierState] = useState<CopierState>(CopierState.displaying)
  const [deleteCopier] = useMutation(deleteCopierQuery, {
    update(cache, {data: {deleteCopier}}) {
      cache.modify({
        fields: {
          copiers(existingCopiers: CopierType[]) {
            return existingCopiers.filter(copier => {
              return copier.id !== deleteCopier;
            });
          }
        }
      })
    }
  });

  const getCopierState = (copierState: CopierState, client: ClientType, copier: CopierType, onEdit: OnCopierEdit) => {
    switch (copierState) {
      case CopierState.displaying:
        return DisplayCopier({copier});
      case CopierState.editing:
        return <EditCopier copier={copier} client={client} onEdit={onEdit}/>;
      default:
        return DisplayCopier({copier});
    }
  }

  const handleEditClicked = () => {
    if (copierState === CopierState.editing) setCopierState(CopierState.displaying);
    else setCopierState(CopierState.editing);
  }

  const handleEdit = () => {
    setCopierState(CopierState.displaying);
  }

  const handleDelete = () => {
    deleteCopier({variables: {id: copier.id}}).then(_ => {
      message.success("Deleted Copier");
    }).catch(err => {
      console.log(err);
      message.error("Could Not Delete Copier");
    });
}

  return (
    <div>
      <Row style={{marginBottom: 10}}>
        <StyledEditText type="text" onClick={handleEditClicked} style={{marginLeft: 'auto'}}>Edit</StyledEditText>
        <Popconfirm onConfirm={handleDelete} title={"Confirm Delete"} okText="Confirm" cancelText="Cancel">
          <StyledEditText type="text" onClick={handleDelete} style={{marginLeft: 10}}>Delete</StyledEditText>
        </Popconfirm>
      </Row>
      {
        getCopierState(copierState, client, copier, handleEdit)
      }
    </div>
  )
}

export default Copier;
