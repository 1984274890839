import React, {useState} from "react";
import {deleteClient as deleteClientQuery, getClient} from '../networking/api/client';
import {useParams} from "react-router";
import {Col, message, Collapse, Row, Tabs, Popconfirm} from "antd";
import Title from "antd/lib/typography/Title";
import {StyledEdit, StyledLoadingIndicator} from "../components/styled-antd";
import ClientInformation from "../components/client/client-information";
import Copiers from "../components/copier/copiers";
import {Client as ClientType} from '../networking/api/client';
import EditClientInformation from "../components/client/edit-client-information";
import MeterReadings from "../components/meter-readings/meter-readings";
import Locations from "../components/client/components/location/locations";
import Invoices from "../components/invoice/invoice";
import {DeleteButton} from "../components/buttons";
import {useMutation, useQuery} from "@apollo/client";
import {useHistory} from "react-router-dom";

const {TabPane} = Tabs;
const {Panel} = Collapse;


const Client = () => {
  const history = useHistory()
  const params = useParams<{ id: string }>();
  const [editing, setEditing] = useState(false);
  const {loading, error, data: {client} = {client: null}} = useQuery<{ client: ClientType | null }>(getClient, {variables: {clientId: params.id}})
  const [deleteClient, {loading: deleting}] = useMutation(deleteClientQuery);


  const handleDelete = () => {
    if (client) {
      deleteClient({variables: {clientId: client._id}}).then(() => {
        message.success("Deleted Client")
        history.goBack();
      }).catch(error => {
        console.log(error);
        message.error("Could Not Delete Client")
      })
    }
  }

  if (loading || deleting) {
    return (
      <div className='client'>
        <StyledLoadingIndicator style={{margin: "20px auto"}}/>
      </div>
    )
  }

  if (!client || error) {
    message.error("Could not get client")
    return null;
  }

  return (
    <Tabs defaultActiveKey="1" centered>
      <TabPane tab={"Info"} key="1">
        <Col span={24} offset={0} xl={{span: 21, offset: 1}} xxl={{span: 20, offset: 2}}>
          <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
            <Title level={2} style={{margin: 0}}>{client?.name}</Title>
            <Popconfirm onConfirm={handleDelete} title={"Confirm Delete"} okText="Confirm" cancelText="Cancel">
              <DeleteButton style={{marginLeft: 'auto'}}/>
            </Popconfirm>
          </Row>
          <Collapse style={{marginTop: 20}}>
            <Panel key={'client'} header={'Information'} extra={<StyledEdit onClick={() => setEditing(true)}/>}>
              {editing ? <EditClientInformation id={client._id} onComplete={() => setEditing(false)}/> :
                <ClientInformation id={client._id}/>}
            </Panel>
            <Panel key={"locations"} header={"Locations"}>
                <Locations clientId={client._id}/>
            </Panel>
            <Panel key={'copiers'} header={'Copiers'}>
              <Copiers client={client}/>
            </Panel>
          </Collapse>
        </Col>
      </TabPane>
      <TabPane tab={'Meter Readings'} key="2">
        <MeterReadings clientId={client._id}/>
      </TabPane>
      <TabPane tab={'Invoices'} key="3">
        <Invoices clientId={client._id}/>
      </TabPane>
    </Tabs>
  )
}

const ClientWrapper = () =>
  <Client/>;

export default ClientWrapper;
