import {gql} from "@apollo/client";

export type Invoice = {
  id: string
  clientId: string
  time: number
  dueDate: number
  readings: [JSON]
  total: number
  balance: number
  qbId: string
}

export type CreateInvoiceArguments = {
  clientId: string
  copiersToInvoice: string[]
}

export const getInvoices = gql`
    query GetInvoices($clientId: String) {
        invoices(clientId: $clientId) {
            id
            qbId
            dueDate
            time
            total
            balance
        }
    }
`;

export const createInvoice = gql`
    mutation CreateInvoice($data: CreateInvoiceInput) {
        createInvoice(data: $data) {
            id
            qbId
            dueDate
            time
            total
            balance
        }
    }
`;

export const deleteInvoice = gql`
    mutation DeleteInvoice($qbId: String!) {
        deleteInvoice(invoiceId: $qbId)
    }
`;
