import React, {useState} from 'react';
import {message, Button, Checkbox, Form, Input, Row} from "antd";
import {ClientInputData, createClient as createClientQuery} from "../../networking/api/client";
import Title from "antd/es/typography/Title";
import {useMutation} from "@apollo/client";

const CreateClientForm = ({hideModal}: { hideModal: VoidFunction }) => {
  const [form] = Form.useForm()
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(true);
  const [createClient, {loading: creating}] = useMutation(createClientQuery);
  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
  };

  const submit = (values: ClientInputData) => {
    if (billingSameAsShipping) {
      values.billingAddress = values.shippingAddress
    }
    createClient({variables: {clientData: values}}).then(() => {
      message.success("Created Client")
      form.resetFields();
      hideModal();
    }).catch(error => {
      console.log(error);
      message.error("Could Not Create Client")
    });
  };


  return (
    <Form {...layout} form={form} initialValues={{billingSameAsShipping: true}} name="create-client" onFinish={submit}>
      <Title level={5}>Client Info</Title>
      <Form.Item label="Name" name="name" rules={[{required: true, message: "Client name is Required!"}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Area" name={["area"]} rules={[{required: true, message: "Area is Required!"}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Email" name="email">
        <Input/>
      </Form.Item>
      <Form.Item label="Phone Number" name="phoneNumber">
        <Input/>
      </Form.Item>
      <Title level={5}>Shipping Address</Title>
      <Form.Item label="Address" name={["shippingAddress", "address"]}
                 rules={[{required: true, message: "Address is Required!"}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="City" name={["shippingAddress", "city"]}
                 rules={[{required: true, message: "City is Required!"}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Province" name={["shippingAddress", "province"]}
                 rules={[{required: true, message: "Province is Required!"}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Postal Code" name={["shippingAddress", "postalCode"]}
                 rules={[{required: true, message: "Postal Code is Required!"}]}>
        <Input/>
      </Form.Item>
      <Checkbox checked={billingSameAsShipping} onChange={e => setBillingSameAsShipping(e.target.checked)}
                style={{paddingBottom: "20px"}}>
        Billing Same As Shipping
      </Checkbox>
      {(!billingSameAsShipping) ?
        <>
          <Title level={5}>Billing Address</Title>
          <Form.Item label="Address" name={["billingAddress", "address"]}
                     rules={[{required: true, message: "Address is Required!"}]}>
            <Input/>
          </Form.Item>
          <Form.Item label="City" name={["billingAddress", "city"]}
                     rules={[{required: true, message: "City is Required!"}]}>
            <Input/>
          </Form.Item>
          <Form.Item label="Province" name={["billingAddress", "province"]}
                     rules={[{required: true, message: "Province is Required!"}]}>
            <Input/>
          </Form.Item>
          <Form.Item label="Postal Code" name={["billingAddress", "postalCode"]}
                     rules={[{required: true, message: "Postal Code is Required!"}]}>
            <Input/>
          </Form.Item>
        </> : null}
      <Row>
        <Button style={{margin: '0 8px'}} onClick={_ => form.resetFields()}>
          Clear
        </Button>
        <Button type="primary" htmlType="submit" disabled={creating}>
          Create
        </Button>
      </Row>
    </Form>
  );
}


export default CreateClientForm;
