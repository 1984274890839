import React, {Dispatch, SetStateAction} from "react";
import CreateClientForm from "./create-client-form";
import {Modal, Tabs} from "antd";

const {TabPane} = Tabs;

type CreateAProps = {
  mounted: boolean;
  setCreateModal: Dispatch<SetStateAction<any>>;
}

const CreateAModal = ({mounted, setCreateModal}: CreateAProps) =>
  <Modal forceRender title={"Create a "} visible={mounted} onCancel={(e) => setCreateModal(false)} footer={null}>
    <Tabs defaultActiveKey="1">
      <TabPane tab={'Client'} key="1">
        <CreateClientForm hideModal={() => {
          setCreateModal(false)
        }}/>
      </TabPane>
      {/*<TabPane tab={'Location'} key="2">*/}
      {/*  <CreateLocationForm hideModal={() => {*/}
      {/*    setCreateModal(false)*/}
      {/*  }}/>*/}
      {/*</TabPane>*/}
    </Tabs>
  </Modal>

export default CreateAModal;