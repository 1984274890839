import styled from 'styled-components';
import {Button, Form, PageHeader, Tag} from "antd";
import {DeleteTwoTone, EditTwoTone, LoadingOutlined, PlusCircleTwoTone, CloseCircleTwoTone} from '@ant-design/icons';

export const StyledPageHeader = styled(PageHeader)`
    border: 1px solid rgb(235, 237, 240);
`;

export const StyledFormItem = styled(Form.Item)`
  margin: 0;
`;

export const StyledDelete = styled(DeleteTwoTone)`
  display: table;
  font-size: 24px;
  cursor: pointer;
  transition: transform .2s; /* Animation */
  :hover {
    transform: scale(1.25);
  }
`;

export const StyledEdit = styled(EditTwoTone)`
  display: table;
  font-size: 24px;
  cursor: pointer;
  transition: transform .2s; /* Animation */
  :hover {
    transform: scale(1.25);
  }
`;
export const StyledEditText = styled(Button)`
  cursor: pointer;
  font-size: 1.2em;
  color: rgb(24, 144, 255);
  display: table;
`;

export const StyledLoadingIndicator = styled(LoadingOutlined)`
    font-size: 36px;
    display: table;
`;

export const StyledPlusButton = styled(PlusCircleTwoTone)`
  cursor: pointer;
  font-size: 30px;
  display: table;
  transition: transform .2s; /* Animation */
  :hover {
    transform: scale(1.25);
  }
`

export const StyledExitButton = styled(CloseCircleTwoTone)`
  cursor: pointer;
  font-size: 30px;
  display: table;
  transition: transform .2s; /* Animation */
  :hover {
    transform: scale(1.25);
  }
`

export const StyledTag = styled(Tag)`
  height: 35px;
  padding: 7px;
  font-size: 17px;
`
