import {gql} from '@apollo/client';
import {FullCopier} from "./fragments/copier";

export type EpochTime = number;

export type KeyValuePair = {
  name: string
  value: number
}

export type Reading = {
  _id: string;
  time: EpochTime;
  data: KeyValuePair[]
  invoiced: boolean;
};

export type ReadingInputType = Omit<Reading, "_id">

export const createMeterReading = gql`
    mutation createMeterReading(
        $copierId: String!,
        $readingData: CreateMeterReadingInput!
    ) {
        createMeterReading(copierId: $copierId, readingData: $readingData) {
            ...FullCopier
        }
    }
    ${FullCopier}
`

export const deleteMeterReading = gql`
    mutation deleteMeterReading($copierId: String!, $readingId: String!) {
        deleteMeterReading(copierId: $copierId, readingId: $readingId) {
            ...FullCopier
        }
    }
    ${FullCopier}
`;

export const editMeterReading = gql`
    mutation editMeterReading($copierId: String!, $readingId: String!, $invoiced: Boolean!) {
        editMeterReading(data: {copierId: $copierId, readingId: $readingId, invoiced: $invoiced}) {
            ...FullCopier
        }
    }
    ${FullCopier}
`
