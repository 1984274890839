import React, {useState} from "react";
import {Radio, Row} from "antd";
import Title from "antd/es/typography/Title";
import ClientAutoComplete from "../../../components/client-autcomplete";
import CopierAutoComplete from "../../../components/copier-autocomplete";
import {RadioChangeEvent} from "antd/es/radio";
import {useHistory} from "react-router-dom";

const HomeSearch = () => {
  const [type, setType] = useState("Client Name");
  const history = useHistory();

  const handleSearch = (value: string) => {
    history.push(`/client/${value}`);
  }

  const handleTypeChange = (e: RadioChangeEvent) => {
    setType(e.target.value);
  }

  return (
    <>
      <Row>
        <Title level={3} style={{marginRight: 7}}>Search By</Title>
        <Radio.Group
          options={["Client Name", "Copier ID"]}
          value={type}
          onChange={handleTypeChange}
          optionType="button"
        />
      </Row>
      {
        (type === "Client Name") ? <ClientAutoComplete styles={{width: '100%'}} onSearch={handleSearch}/>
          : <CopierAutoComplete styles={{width: '100%'}} onSearch={handleSearch}/>
      }

    </>
  )
};

export default HomeSearch;
