import {Button, Descriptions, Form, Input, message} from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import React from "react";
import {StyledFormItem, StyledLoadingIndicator} from "../styled-antd";
import {useMutation, useQuery} from "@apollo/client";
import {Client as ClientType, getClient, editClient as editClientQuery} from "../../networking/api/client";

function EditClientInformation({id, onComplete}: { id: string, onComplete: VoidFunction }) {
  const [form] = Form.useForm()
  const {error, data: {client} = {client: null}} = useQuery<{ client: ClientType | null }>(getClient, {variables: {clientId: id}})
  const [editClient, {loading: editing}] = useMutation(editClientQuery, {
    update(cache, {data: {editClient}}) {
      cache.modify({
        fields: {
          client(currentClient: ClientType | null = null) {
            return editClient
          }
        }
      })
    }
  });

  if (error) {
    message.error("Could not update client")
    return null
  }

  if (!client) return null;

  const submit = (data: any) => {
    editClient({
      variables: {
        id: client._id,
        qbId: client.qbId,
        clientData: data
      }
    }).then(_ => {
      message.success("Edited client")
      onComplete()
    }).catch(err => {
      console.log(err);
      message.error("Could not edit client")
    })
  }

  if (editing) {
    return <StyledLoadingIndicator style={{margin: "20px auto"}}/>
  }

  return (
    <Form form={form} initialValues={client!} onFinish={submit}>
      <Descriptions column={3} layout={'horizontal'} bordered>
        <Descriptions.Item span={3} label="ID">{client!._id}</Descriptions.Item>
        <Descriptions.Item span={3} label="Name"><StyledFormItem
          name="name"><Input/></StyledFormItem></Descriptions.Item>
        <Descriptions.Item label="Email" span={1}><StyledFormItem
          name="email"><Input/></StyledFormItem></Descriptions.Item>
        <Descriptions.Item label="Phone Number" span={1}><StyledFormItem
          name="phoneNumber"><Input/></StyledFormItem></Descriptions.Item>
        <Descriptions.Item label="Area" span={1}><StyledFormItem
          name={["area"]}><Input/></StyledFormItem></Descriptions.Item>
        <Descriptions.Item span={3} label="Shipping Address">
          <StyledFormItem name={["shippingAddress", "address"]}>
            <Input/>
          </StyledFormItem>
        </Descriptions.Item>
        <Descriptions.Item span={1} label="City">
          <StyledFormItem name={["shippingAddress", "city"]}>
            <Input/>
          </StyledFormItem>
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Postal">
          <StyledFormItem name={["shippingAddress", "postalCode"]}>
            <Input/>
          </StyledFormItem>
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Province">
          <StyledFormItem name={["shippingAddress", "province"]}>
            <Input/>
          </StyledFormItem>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Billing Address">
          <StyledFormItem name={["billingAddress", "address"]}>
            <Input/>
          </StyledFormItem>
        </Descriptions.Item>
        <Descriptions.Item span={1} label="City">
          <StyledFormItem name={["billingAddress", "city"]}>
            <Input/>
          </StyledFormItem>
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Postal">
          <StyledFormItem name={["billingAddress", "postalCode"]}>
            <Input/>
          </StyledFormItem>
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Province">
          <StyledFormItem name={["billingAddress", "province"]}>
            <Input/>
          </StyledFormItem>
        </Descriptions.Item>
        <DescriptionsItem label={"Quickbooks"} span={3}>
          <a
            href={`https://app.qbo.intuit.com/app/customerdetail?nameId=${client!.qbId}`}> https://app.qbo.intuit.com/app/customerdetail?nameId={client!.qbId}</a>
        </DescriptionsItem>
      </Descriptions>
      <Button type="primary" htmlType="submit" style={{marginTop: 10}}>
        Update
      </Button>
    </Form>
  )
}

export default EditClientInformation;
