import React, {useState} from "react";
import {Client as ClientType} from "../../networking/api/client";
import Copier from "./components/copier";
import {StyledLoadingIndicator, StyledPlusButton} from "../styled-antd";
import {useQuery} from "@apollo/client";
import {getCopiers, Copier as CopierType} from "../../networking/api/copier";
import CreateCopierModal from "./components/create-copier-modal";

const Copiers = ({client}: { client: ClientType }) => {
  const [showCreateCopier, setShowCreateCopier] = useState(false);
  const {loading, data: {copiers} = {copiers: []}} = useQuery<{ copiers: CopierType[] }>(getCopiers, {variables: {clientId: client._id}})

  if (loading) {
    return <StyledLoadingIndicator style={{margin: "20px auto"}}/>
  }

  return (
    <>
      <CreateCopierModal clientId={client._id} mounted={showCreateCopier} setMounted={setShowCreateCopier}/>
      <ul>
        {
          copiers.map(copier =>
            <Copier client={client} copier={copier} key={copier.id}/>
          )
        }
      </ul>
      <StyledPlusButton style={{margin: "20px auto"}} onClick={() => setShowCreateCopier(true)}/>
    </>
  )
}

export default Copiers;
