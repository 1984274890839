import React from "react";
import {AutoComplete, Input} from "antd";
import {Client, clientSearch as clientSearchQuery} from "../networking/api/client";
import {useLazyQuery} from "@apollo/client";

type ClientAutoCompleteProps = {
  onSelect?: (id: string) => void
  onSearch?: (id: string) => void
  styles?: any
}

const ClientAutoComplete = ({onSelect, onSearch, styles}: ClientAutoCompleteProps) => {
  const [clientSearch, {data: {clientSearch: clients} = {clientSearch: []}}] = useLazyQuery<{ clientSearch: Client[] }>(clientSearchQuery)

  const onChange = (query: string) => {
    if (!query) return;
    try {
      clientSearch({variables: {query}})
    } catch (e) {
      console.log(e);
    }
  }

  const options = clients?.map((client: Client) => {
    return {
      label: client.name,
      value: client._id
    }
  });

  return (<AutoComplete onChange={onChange} onSelect={onSelect} style={styles} options={options}>
    {onSearch ?
      <Input.Search key="search" size="large" enterButton onSearch={onSearch}/> :
      null
    }
  </AutoComplete>)
}

export default ClientAutoComplete;
