import {Descriptions} from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import React from "react";
import {StyledLoadingIndicator} from "../styled-antd";
import {useQuery} from "@apollo/client";
import {Client as ClientType, getClient} from "../../networking/api/client";

const ClientInformation = ({id}: { id: string }) => {
  const {loading, error, data: {client} = {client: null}} = useQuery<{ client: ClientType | null }>(getClient, {variables: {clientId: id}})


  if (loading) {
    return <StyledLoadingIndicator style={{margin: "20px auto"}}/>
  }

  if (!client || error) return null;

  return (
    <Descriptions column={3} layout={'horizontal'} bordered>
      <Descriptions.Item span={3} label="ID">{client._id}</Descriptions.Item>
      <Descriptions.Item span={3} label="Name">{client.name}</Descriptions.Item>
      <Descriptions.Item label="Email">{client.email || "N/A"}</Descriptions.Item>
      <Descriptions.Item label="Phone Number" style={{minWidth: 200}}>{client.phoneNumber || "N/A"}</Descriptions.Item>
      <Descriptions.Item label="Area">{client.area || "N/A"}</Descriptions.Item>
      <Descriptions.Item span={3} label="Shipping Address">
        {client.shippingAddress.address}
        <br/>
        {client.shippingAddress.city}, {client.shippingAddress.province}
        <br/>
        {client.shippingAddress.postalCode}
      </Descriptions.Item>
      <Descriptions.Item span={2} label="Billing Address">
        {client.billingAddress.address}
        <br/>
        {client.billingAddress.city}, {client.billingAddress.province}
        <br/>
        {client.billingAddress.postalCode}
      </Descriptions.Item>
      <DescriptionsItem label={"Quickbooks"}>
        <a
          href={`https://app.qbo.intuit.com/app/customerdetail?nameId=${client.qbId}`}> https://app.qbo.intuit.com/app/customerdetail?nameId={client.qbId}</a>
      </DescriptionsItem>
    </Descriptions>
  )
}


export default ClientInformation;
