import React from "react";
import {setCookie} from 'tiny-cookie';
import {Button, Col, Form, Input, message} from "antd";
import styled from "styled-components";
import Title from "antd/es/typography/Title";
import {LoginCredentials, login as loginToServer} from "../../networking/auth/login";

const LoginWrapper = styled(Col)`
  margin-top: 20px;
  border: 2px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
`;

const StyledTitle = styled(Title)`
  color: gray !important;
  text-align: center;
`
const LoginButton = styled(Button)`
  margin: 5px auto;
  display: table;
`

const Login = () => {
  const layout = {
    labelCol: {span: 4},
  };


  const login = (credentials: LoginCredentials) => {
    loginToServer(credentials).then(response => {
      const {data} = response;
      setCookie("jwt", data as string, {"max-age": 86400});
      window.location.href = "https://cofax.nafeesrawji.com"
    }).catch(err => {
      console.log(err);
      message.error("Could not log in");
    })
  }

  return (
    <LoginWrapper span={8} offset={8}>
      <StyledTitle level={3}>Login</StyledTitle>
      <Form {...layout} onFinish={login}>
        <Form.Item
          label="Email"
          name="username"
          rules={[{required: true, message: 'Please input your email!'}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{required: true, message: 'Please input your password!'}]}
        >
          <Input.Password/>
        </Form.Item>
        <LoginButton type="primary" htmlType="submit" style={{marginTop: 10}}>
          Login
        </LoginButton>
      </Form>
    </LoginWrapper>
  )
}

export default Login;
