import {gql} from "@apollo/client";

export const FullClient = gql`
    fragment FullClient on Client {
        _id
        qbId
        name
        area
        email
        phoneNumber
        billingAddress {
            address
            city
            postalCode
            province
        }
        shippingAddress {
            address
            city
            postalCode
            province
        }
        locations {
            name
            area
            phoneNumber
            email
            address {
                address
                city
                postalCode
                province
            }
        }
    }
`;