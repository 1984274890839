import {ApolloClient, InMemoryCache} from '@apollo/client';
import {getCookie} from "tiny-cookie";

const url = {
  prod: "https://api.nafeesrawji.com:8443/graphql",
  dev: "https://api.nafeesrawji.com:8443/graphql"
};


const client = new ApolloClient({
  uri: process.env.REACT_APP_STAGE === 'prod' ? url.prod : url.dev,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${getCookie('jwt')}`,
  }
});

export default client;
