import React, {Dispatch, SetStateAction} from "react";
import {Modal} from "antd";
import CreateLocationForm from "./create-location-form";

type ModalProps = {
  clientId: string;
  mounted: boolean;
  setMounted: Dispatch<SetStateAction<any>>;
}

const CreateLocationModal = ({clientId, mounted, setMounted}: ModalProps) =>
  <Modal title={"Create a Location"} visible={mounted} onCancel={() => setMounted(false)} footer={null}>
    <CreateLocationForm hideModal={() => {
      setMounted(false)
    }} clientId={clientId}/>
  </Modal>;

export default CreateLocationModal;