import {Button, Col, Popconfirm, Select, Space, Table} from "antd";
import React, {useState} from "react";
import {Copier as CopierType, getCopiers as getCopiersQuery} from "../../networking/api/copier";
import {uppercaseFirst} from "../copier/components/display-copier";
import {deleteMeterReading as deleteMeterReadingQuery, editMeterReading as editMeterReadingQuery, Reading} from "../../networking/api/meter-readings";
import {ColumnsType} from "antd/es/table";
import {StyledPlusButton} from "../styled-antd";
import EnterMeterReading from "./enter-meter-reading";
import Title from "antd/lib/typography/Title";
import {DeleteButton} from "../buttons";
import {useMutation, useQuery} from "@apollo/client";
import {Client as ClientType, getClient} from "../../networking/api/client";

const { Option } = Select;


const formatReadingData = (readings: Reading[]) => {
  return readings.map(reading => {
    const readingDataFormatted: Record<string, number> = {}
    reading.data.forEach(data => {
      readingDataFormatted[data.name] = data.value
    });
    return {
      time: reading.time,
      data: readingDataFormatted,
      id: reading._id,
      key: reading._id,
      invoiced: reading.invoiced
    }
  })
}

const MeterReading = ({copier}: { copier: CopierType}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  
  const [deleteMeterReading] = useMutation(deleteMeterReadingQuery, {
    update(cache, {data: {deleteMeterReading}}) {
      cache.modify({
        fields: {
          copiers(currentCopiers: CopierType[]) {
            return currentCopiers.map<CopierType>(copier => {
              if (copier.id === deleteMeterReading.id) return deleteMeterReading;
              return copier;
            });
          }
        }
      })
    }
  });

  const [editMeterReading] = useMutation(editMeterReadingQuery, {
    update(cache, {data: {editMeterReading}}) {
      cache.modify({
        fields: {
          copiers(currentCopiers: CopierType[]) {
            return currentCopiers.map<CopierType>(copier => {
              if (copier.id === editMeterReading.id) return editMeterReading;
              return copier;
            });
          }
        }
      })
    }
  });

  const [addReading, setAddReading] = useState<boolean>(false)
  const formattedReadingData = formatReadingData(copier.readings);
  formattedReadingData.sort((a, b) => (b.time - a.time))

  const columns: ColumnsType<any> = [{
    title: "Date",
    dataIndex: "time",
    render: (data: number) => {
      const date = new Date(data * 1000);
      return date.toDateString();
    }
  }, {title: "Invoiced", dataIndex: "invoiced", render: (invoiced: boolean, reading: Reading) => {
    if (isEditing) {
      return <Select defaultValue={`${reading.invoiced}`} onChange={(value) => {reading.invoiced = value === 'true'}}>
        <Option value="true">Yes</Option>
        <Option value="false">No</Option>
      </Select>
    }
    return invoiced ? "Yes" : "No"
  }}];

  copier.readings[0]?.data.forEach((reading) => {
    columns.push({
      title: uppercaseFirst(reading.name),
      dataIndex: ["data", reading.name]
    })
  })

  columns.push({
    title: 'Action',
    key: 'action',
    render: (text, reading) => (
      isEditing? 
      <>
        <Button onClick={() => {
          editMeterReading({variables: {copierId: copier.id, readingId: reading.id, invoiced: reading.invoiced}})
          setIsEditing(false);
        }}>Save</Button>
      </>:
      <>
        <Space>
          <Button onClick={() => setIsEditing(true)}>Edit</Button>
          <Popconfirm onConfirm={() => {
              deleteMeterReading({variables: {copierId: copier.id, readingId: reading.id}})
            }} title={"Confirm Delete"} okText="Confirm" cancelText="Cancel">
              <DeleteButton/>
          </Popconfirm>
        </Space>
      </>
    ),
  },)

  return (
    <div>
      <Table dataSource={formattedReadingData} columns={columns} pagination={{pageSize: 3}} bordered/>
      {!addReading ? <StyledPlusButton style={{margin: "20px auto"}} onClick={() => setAddReading(true)}/> :
        <EnterMeterReading copier={copier} dismiss={() => setAddReading(false)}/>}
    </div>
  )
}

const MeterReadings = ({clientId}: { clientId: string }) => {
  const [location, setLocation] = useState<string | null>();
  const {data: {client} = {client: null}} = useQuery<{ client: ClientType | null }>(getClient, {variables: {clientId}})
  const {data: {copiers} = {copiers: []}} = useQuery<{ copiers: CopierType[] }>(getCopiersQuery, {variables: {clientId}});

  const getCopiers = () => {
    if (location) {
      return copiers.filter(copier => copier.location === location);
    }
    return copiers
  }

  const getOptions = () => {
    if (!client?.locations) return [];
    const options: any = client.locations.map(location => ({label: location.name, value: location.name}))
    options.unshift({label: "All", value: null})
    return options
  }


  return (
    <Col span={14} offset={5}>
      {client?.locations ?
        <Select style={{width: 200}} onChange={(location: any) => setLocation(location)} options={getOptions()}/>
        : null}
      {getCopiers().map(copier =>
        <div key={copier.id}>
          <Title level={3} style={{margin: "20px 0"}}>{copier.copierModel} - {copier.id}</Title>
          <MeterReading copier={copier} key={copier.id}/>
        </div>
      )}
    </Col>
  )
}

export default MeterReadings;
