import React, {useState} from "react";
import {StyledLoadingIndicator, StyledTag} from "../styled-antd";
import {Button, Col, Popconfirm, Row} from "antd";
import Title from "antd/es/typography/Title";
import {deleteInvoice as deleteInvoiceQuery, getInvoices, Invoice} from "../../networking/api/invoice";
import CreateInvoice from "./create-invoice";
import {DeleteButton, PrintButton} from "../buttons";
import {useMutation, useQuery} from "@apollo/client";


const Invoices = ({clientId}: { clientId: string }) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const {loading, data: {invoices} = {invoices: []}} = useQuery<{ invoices: Invoice[] }>(getInvoices, {variables: {clientId}})
  const [deleteInvoice, {loading: deleting}] = useMutation(deleteInvoiceQuery, {
    update(cache, {data: {deleteInvoice}}) {
      cache.modify({
        fields: {
          invoices(existingInvoices: Invoice[] = []) {
            return existingInvoices.filter(invoice => invoice.qbId !== deleteInvoice)
          }
        }
      });
      cache.evict({
        fieldName: "copiers"
      })
    }
  });

  const InvoiceStatus = (invoice: Invoice) => {
    if (invoice.balance === 0) {
      return <StyledTag color={"#2AD909"} style={{margin: "auto 0 auto auto"}}>PAID</StyledTag>
    } else if (invoice.balance > 0 && (Date.now() / 1000 < invoice.dueDate)) {
      return <StyledTag color={"#90aad6"} style={{margin: "auto 0 auto auto"}}>PENDING</StyledTag>
    }
    return <StyledTag color={"#f50"} style={{margin: "auto 0 auto auto"}}>LATE</StyledTag>
  }

  const PrintInvoice = (invoice: Invoice) => {
    window.open(`https://c5.qbo.intuit.com/qbo5/neo/v1/company/9130349216264846/purchsales/print?txnId=${invoice.qbId}&txnTypeId=4`)
  }


  if (loading || deleting) {
    return <StyledLoadingIndicator style={{margin: "20px auto"}}/>
  }

  return (
    <>
      <CreateInvoice visible={modalVisible} setVisible={setModalVisible} clientId={clientId}/>
      <Col span={8} offset={8}>
        <Button type={"primary"} style={{margin: "5px auto 20px auto", display: "table"}}
                onClick={() => setModalVisible(true)}>Create Invoice</Button>
      </Col>
      {
        invoices.slice().reverse().map(invoice =>
          <Col span={8} offset={8}
               style={{border: "2px solid lightGray", borderRadius: 5, padding: 10, marginBottom: 20}}>
            <Row>
              <Title level={2} style={{margin: "auto 5px"}}>Invoice</Title>
              <PrintButton style={{display: "table", margin: "10px 0 10px auto"}}
                           onClick={() => PrintInvoice(invoice)}/>
              <Popconfirm onConfirm={() => deleteInvoice({variables: {qbId: invoice.qbId}})} title={"Confirm Delete"}
                          okText="Confirm" cancelText="Cancel">
                <DeleteButton style={{display: "table", margin: "10px 0 10px 10px"}}/>
              </Popconfirm>
            </Row>
            <Row align={"middle"} style={{padding: "15px 5px", borderBottom: "2px solid lightGray"}}>
              <Title level={3} style={{margin: "auto 0"}}>Total Billed: <span
                style={{fontWeight: "normal"}}>${invoice.total.toFixed(2)}</span></Title>
              {InvoiceStatus(invoice)}
            </Row>
            <Row align={"middle"} style={{padding: "15px 5px", borderBottom: "2px solid lightGray"}}>
              <Title level={5} style={{margin: "auto 0"}}>Outstanding: <span
                style={{fontWeight: "normal", color: "red"}}>${invoice.balance.toFixed(2)}</span></Title>
            </Row>
            <Row align={"middle"} style={{padding: "15px 5px", borderBottom: "2px solid lightGray"}}>
              <Title level={5} style={{margin: "auto 0"}}>Invoice Date: <span
                style={{fontWeight: "normal"}}>{new Date(invoice.time * 1000).toDateString()}</span></Title>
            </Row>
            <Row align={"middle"} style={{padding: "15px 5px", borderBottom: "2px solid lightGray"}}>
              <Title level={5} style={{margin: "auto 0"}}>Due Date: <span
                style={{fontWeight: "normal"}}>{new Date(invoice.dueDate * 1000).toDateString()}</span></Title>
            </Row>
            <Row align={"middle"} style={{padding: "15px 5px"}}>
              <Title level={5} style={{margin: "auto 0"}}>Quickbooks Link: <a
                href={`https://app.qbo.intuit.com/app/invoice?txnId=${invoice.qbId}`}
                style={{fontWeight: "normal"}}>https://app.qbo.intuit.com/app/invoice?txnId={invoice.qbId}</a></Title>
            </Row>
          </Col>
        )
      }
    </>
  )
}
export default Invoices;
