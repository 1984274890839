import React from "react";
import {AutoComplete, Input} from "antd";
import {Copier, copierSearch as copierSearchQuery} from "../networking/api/copier";
import {useLazyQuery} from "@apollo/client";

type CopierAutocompleteProps = {
  onSelect?: (id: string) => void
  onSearch?: (id: string) => void
  styles?: any
}

const CopierAutoComplete = ({onSelect, onSearch, styles}: CopierAutocompleteProps) => {
  const [copierSearch, {data: {copierSearch: copiers} = {copierSearch: []}}] = useLazyQuery<{ copierSearch: Copier[] }>(copierSearchQuery)

  const onChange = async (query: string) => {
    await copierSearch({variables: {query}});
  }

  const options = copiers.map((copier: Copier) => {
    return {
      label: `${copier.id} - ${copier.client?.name}`,
      value: copier.client?._id ?? ""
    }
  });

  return (<AutoComplete onChange={onChange} onSelect={onSelect} style={styles} options={options}>
    {onSearch ?
      <Input.Search key="search" size="large" enterButton onSearch={onSearch}/> :
      null
    }
  </AutoComplete>)
}

export default CopierAutoComplete;
