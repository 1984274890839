import {Button, Descriptions, Form, Input, InputNumber, message, Select} from "antd";
import React from "react";
import {
  Copier as CopierType,
  editCopier as editCopierQuery,
  EditCopierData
} from "../../../networking/api/copier";
import {StyledFormItem} from "../../styled-antd";
import {
  Client as ClientType,
} from "../../../networking/api/client";
import CopierRatesField from "../../create/copier-rates-field";
import Text from "antd/es/typography/Text";
import {uppercaseFirst} from "./display-copier";
import {OnCopierEdit} from "./copier";
import {useMutation} from "@apollo/client";

const EditCopier = ({copier, client, onEdit}: { copier: CopierType, client: ClientType, onEdit: OnCopierEdit }) => {
  const lastReading = copier.readings[copier.readings.length - 1];
  const [editCopier, {loading: editing}] = useMutation(editCopierQuery, {
    update(cache, {data: {editCopier}}) {
      cache.modify({
        fields: {
          copiers(currentCopiers: CopierType[]) {
            return currentCopiers.map<CopierType>(copier => {
              if (copier.id === editCopier.id) return editCopier;
              return copier;
            });
          }
        }
      })
    }
  });
  const [form] = Form.useForm()
  const handleSubmit = (copierData: EditCopierData) => {
    console.log(copierData);
    copierData.rates = copierData.rates.map(rate => ({name: rate.name, value: rate.value}));
    editCopier({variables: {id: copierData.id, copierData}}).then(() => {
      message.success("Edited Copier");
      onEdit();
    }).catch(err => {
      console.log(err);
      message.error("Could not update Copier")
    })
  }

  return (
    <Form form={form} initialValues={copier} onFinish={handleSubmit}>
      <Descriptions column={2} bordered style={{marginBottom: 20}}>
        <Descriptions.Item label={"ID"} span={2}><StyledFormItem name="id"><Input/></StyledFormItem></Descriptions.Item>
        <Descriptions.Item label={"Location"} span={2}><StyledFormItem name="location"><Select
          options={client.locations.map(location => ({label: location.name, value: location.name}))}/></StyledFormItem></Descriptions.Item>
        <Descriptions.Item label={"Model"} span={2}><StyledFormItem
          name="copierModel"><Input/></StyledFormItem></Descriptions.Item>
        <Descriptions.Item label={"PrintFleet Id"} span={2}><StyledFormItem
          name="printFleetId"><Input/></StyledFormItem></Descriptions.Item>
        <Descriptions.Item label={"Rental Price"} span={2}><StyledFormItem
          name="rentalPrice"><InputNumber/></StyledFormItem></Descriptions.Item>
        <Descriptions.Item label="Last Reading">
          {(lastReading) ? lastReading.data.map(reading =>
            <>
              <Text strong>{uppercaseFirst(reading.name)}</Text>: {reading.value}
              <br/>
            </>
          ) : "N/A"}
        </Descriptions.Item>
      </Descriptions>
      <CopierRatesField/>
      <Button type="primary" htmlType="submit" style={{marginTop: 10}} disabled={editing}>
        Update
      </Button>
    </Form>
  )
}

export default EditCopier;
