import React, {useState} from "react";
import {Client as ClientType, getClient} from "../../../../networking/api/client";
import {Location as ILocation} from "../../../../networking/api/location";
import {Descriptions} from "antd";
import {StyledEditText, StyledPlusButton} from "../../../styled-antd";
import EditLocationInformation from "../../edit-location-information";
import {useQuery} from "@apollo/client";
import CreateLocationModal from "./create-location-modal";

const LocationBody = ({location}: { location: ILocation }) =>
  <Descriptions column={3} bordered style={{marginBottom: 20}}>
    <Descriptions.Item label={"Name"} span={3}>{location.name}</Descriptions.Item>
    <Descriptions.Item label="Email">{location.email || "N/A"}</Descriptions.Item>
    <Descriptions.Item label="Phone Number">{location.phoneNumber || "N/A"}</Descriptions.Item>
    <Descriptions.Item label="Area">{location.area || "N/A"}</Descriptions.Item>
    <Descriptions.Item span={3} label="Address">
      {location.address.address}
      <br/>
      {location.address.city}, {location.address.province}
      <br/>
      {location.address.postalCode}
    </Descriptions.Item>
  </Descriptions>

const Location = ({location, client}: { location: ILocation, client: ClientType }) => {
  const [editing, setEditing] = useState<boolean>(false);

  const handleEdit = () => {
    setEditing(false)
  }

  if (!client) return null;

  return (
    <div>
      <StyledEditText type="text" onClick={() => setEditing(!editing)}
                      style={{marginBottom: 5, marginLeft: "auto"}}>Edit</StyledEditText>
      {
        editing ? <EditLocationInformation clientId={client._id} onEdit={handleEdit} location={location}/>
          : <LocationBody location={location} key={location.name}/>
      }
    </div>
  )
}

const Locations = ({clientId}: { clientId: string }) => {
  const [showCreateLocation, setShowCreateLocation] = useState(false);
  const {data: {client} = {client: null}} = useQuery<{ client: ClientType | null }>(getClient, {variables: {clientId: clientId}})
  if (!client) return null;

  return (
    <>
      <CreateLocationModal clientId={clientId} mounted={showCreateLocation} setMounted={setShowCreateLocation}/>
      {client.locations.length > 0 ? 
       <ul>
        {client.locations.map(location => <Location location={location} client={client}/>)}
      </ul>
      : null}
     
      <StyledPlusButton style={{margin: "20px auto"}} onClick={() => setShowCreateLocation(true)}/>
    </>
  )
}


export default Locations;
