import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter as Router,
  Switch,
  Route, Link
} from "react-router-dom";

import 'antd/dist/antd.css';
import Home from './pages/home/home';
import {StyledPageHeader} from './components/styled-antd';
import {Button} from "antd";
import CreateAModal from "./components/create/create-a-modal";
import Client from "./pages/client";
import {ProtectedRoute, ProtectedRouteProps} from "./components/protected-route";
import Login from "./components/login/login";
import {getCookie} from 'tiny-cookie';
import {ApolloProvider} from "@apollo/client";
import client from "./networking/api/config/apollo";

const App = () => {
  const [createModal, setCreateModal] = useState(false);

  const defaultPermissions: ProtectedRouteProps = {
    isAllowed: true,
    isAuthenticated: getCookie("jwt") !== null,
    restrictedPath: "/login",
    authenticationPath: "/login"
  }

  return (
    <ApolloProvider client={client}>
      <>
        <CreateAModal mounted={createModal} setCreateModal={setCreateModal}/>
        <Router>
          <StyledPageHeader title={<Link to={'/'}>Cofax Business Systems</Link>}
                            extra={[<Button key="1" type={"primary"}
                                            onClick={_ => setCreateModal(true)}>Create</Button>]}/>
          <Switch>
            <ProtectedRoute  {...defaultPermissions} exact path='/' component={Home}/>
            <Route exact path='/login'>
              <Login/>
            </Route>
            <ProtectedRoute  {...defaultPermissions} path="/client/:id" component={Client}/>

          </Switch>
        </Router>
      </>
    </ApolloProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
