import {message, Table} from "antd";
import React, {useState} from "react";
import {useQuery} from "@apollo/client";
import {Client, getClients} from "../../../networking/api/client";
import {StyledLoadingIndicator} from "../../../components/styled-antd";
import {useHistory, useLocation} from "react-router-dom";
import qs from "qs";

const ClientList = ({style}: { style?: React.CSSProperties }) => {
  const {loading, data: {clients} = {clients: []}} = useQuery<{ clients: Client[] }>(getClients, {fetchPolicy: "network-only"})
  const history = useHistory();
  const location = useLocation();
  const [shownTooltip, setShownTooltip] = useState(false);

  const page = parseInt(qs.parse(location.search, {ignoreQueryPrefix: true}).page as string | undefined || "1");

  const handleRowActions = (r: any) => ({
    onClick: () => {
      if (!shownTooltip) {
        message.info("Double Click To Go To Client")
      }
      setShownTooltip(true);
    },
    onDoubleClick: () => {
      history.push(`/client/${r._id}`);
    }
  });

  const onChange = (pagination: any, filters: any, sorter: any) => {
    history.push({
      pathname: '/',
      search: `?page=${pagination.current}`
    })
  }

  if (loading) {
    return <StyledLoadingIndicator style={{margin: "20px auto"}}/>

  }

  const clientsWithKey = clients.map<Client & { key: string }>((client: Client) => ({...client, key: client._id}));
  return (
    <Table columns={[
      {title: "Name", dataIndex: 'name', key: 'name', width: '25%',},
      {title: "Client Id", dataIndex: '_id', key: '_id', width: '25%',},
      {title: "Email", dataIndex: "email", key: "email", width: '25%',},
      {title: "Phone Number", dataIndex: 'phoneNumber', key: 'phoneNumber', width: '25%',}
    ]} dataSource={clientsWithKey} onRow={handleRowActions} onChange={onChange} pagination={{defaultCurrent: page}}
           style={style}/>
  )
}

export default ClientList;
