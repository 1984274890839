import {gql} from "@apollo/client";

export const FullCopier = gql`
    fragment FullCopier on Copier {
        id
        location
        copierModel
        rates {
            name
            value
        }
        readings {
            _id
            time
            data {
                name
                value
            }
            invoiced
        }
        printFleetId
        rentalPrice
    }
`;