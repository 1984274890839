import {Button, Col, Form, InputNumber, Select, Space} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import React from "react";

const CopierRatesField = () =>
  <Form.List name="rates">
    {(fields, {add, remove}) => {
      return (
        <Col offset={8}>
          {fields.map(field => (
            <Space key={field.key} style={{display: 'flex', marginBottom: 8}} align="start">
              <Form.Item
                {...field}
                name={[field.name, 'name']}
                fieldKey={[field.fieldKey, 'name']}
                rules={[{required: true, message: 'Missing name'}]}
              >
                <Select options={[{label: "Mono", value: "mono"}, {label: "Color", value: "color"}, {
                  label: "Office Color",
                  value: "officeColor"
                }]} style={{width: 150}}/>
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, 'value']}
                fieldKey={[field.fieldKey, 'value']}
                rules={[{required: true, message: 'Missing value'}]}
              >
                <InputNumber min={0} step={0.1}/>
              </Form.Item>

              <MinusCircleOutlined
                onClick={() => {
                  remove(field.name);
                }}
              />
            </Space>
          ))}

          <Form.Item>
            <Button
              type="dashed"
              onClick={() => {
                add();
              }}
              block
            >
              <PlusOutlined/> Add rate
            </Button>
          </Form.Item>
        </Col>
      );
    }}
  </Form.List>

export default CopierRatesField;