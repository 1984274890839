import {Row} from "antd";
import React, {useEffect, useRef} from "react";
import {ratesChart, readingsChart} from "../../../networking/charts/charts";

const HomeCharts = () => {
  const ratesRef = useRef<HTMLDivElement>(null);
  const readingsRef = useRef<HTMLDivElement>(null);
  const readings = readingsChart();
  const rates = ratesChart();

  useEffect(() => {
    if (ratesRef.current !== null) {
      rates.render(ratesRef.current).then(_ => {
        return rates.setMaxDataAge(60)
      }).catch(e => console.log(e));
    }

    if (readingsRef.current != null) {
      readings.render(readingsRef.current).then(_ => {
        return readings.setMaxDataAge(60)
      }).catch(e => console.log(e));
    }
  });

  return (
    <Row>
      <div className={"rates"} ref={ratesRef} style={{height: 1000, width: "50%"}}/>
      <div className={"readings"} ref={readingsRef} style={{height: 1000, width: "50%"}}/>
    </Row>
  )
}


export default HomeCharts;
