import React from 'react';
import {Button, Form, Input, InputNumber, message, Row, Select} from "antd";
import {Copier as CopierType, CopierInputData, createCopier as createCopierQuery} from "../../../networking/api/copier";
import {
  Client as ClientType,
  getClient as getClientQuery
} from "../../../networking/api/client";
import CopierRatesField from "../../create/copier-rates-field";
import {useMutation, useQuery} from "@apollo/client";


const CreateCopierForm = ({hideModal, clientId}: { hideModal: VoidFunction, clientId: string}) => {
  const {data: {client} = {client: null}} = useQuery<{ client: ClientType | null }>(getClientQuery, {variables: {clientId}})
  const [createCopier, {loading: creating}] = useMutation(createCopierQuery, {
    update(cache, {data: {createCopier}}) {
      cache.modify({
        fields: {
          copiers(currentCopiers: CopierType[]) {
           return [...currentCopiers, createCopier]
          }
        }
      })
    }
  });

  const [form] = Form.useForm();
  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
  };

  const submit = (formData: Omit<CopierInputData, "copierId">) => {
    const copierData: CopierInputData = {...formData, clientId};
    createCopier({variables: {copierData}}).then(() => {
      form.resetFields();
      message.success("Created Copier")
      hideModal();
    }).catch((error) => {
      console.log(error)
      message.error("Could Not Create Copier")
    });
  };

  return (
    <Form {...layout} name="create-copier" onFinish={(values: CopierInputData) => submit(values)} form={form}>
      <Form.Item label="Copier Id" name="id" rules={[{required: true, message: "Copier id is Required!"}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Location" name="location">
        <Select options={client?.locations.map(location => ({label: location.name, value: location.name}))}/>
      </Form.Item>
      <Form.Item label="Copier Model" name="copierModel" rules={[{required: true, message: "Copier id is Required!"}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Rental Price" name="rentalPrice">
        <InputNumber defaultValue={0}/>
      </Form.Item>
      <CopierRatesField/>
      <Row style={{textAlign: 'right'}}>
        <Button style={{margin: '0 8px'}} onClick={_ => form.resetFields()}>
          Clear
        </Button>
        <Button type="primary" htmlType="submit" disabled={creating}>
          Create
        </Button>
      </Row>
    </Form>
  );
}


export default CreateCopierForm;
