import {gql} from '@apollo/client';
import {Location} from './location';
import {FullClient} from "./fragments/client";

export type Address = {
  address: string;
  city: string;
  province: string;
  postalCode: string;
}

export type ClientInputData = {
  name: string;
  area?: string;
  email: string;
  phoneNumber: string;
  billingAddress?: Address;
  shippingAddress: Address;
}

export type Client = {
  _id: string;
  area?: string;
  name: string;
  qbId: string;
  email?: string;
  phoneNumber?: string;
  billingAddress: Address;
  shippingAddress: Address;
  locations: Location[]
}

export const createClient = gql`
    mutation createClient($clientData: CreateClientInput) {
        createClient(clientData: $clientData) {
            ...FullClient
        }
    }
    ${FullClient}
`;


export const editClient = gql`
    mutation editClient($id: String!, $qbId: String!, $clientData: EditClientInput) {
        editClient(id: $id, qbId: $qbId, clientData: $clientData) {
            ...FullClient
        }
    }
    ${FullClient}
`;

export const clientSearch = gql`
    query clientSearch($query: String!) {
        clientSearch(query: $query) {
            _id
            name
        }
    }
`;

export const getClient = gql`
    query client($clientId: String!) {
        client(id: $clientId) {
            ...FullClient
        }
    }
    ${FullClient}
`

export const deleteClient = gql`
    mutation deleteClient($clientId: String!) {
        deleteClient(id: $clientId) {
            _id
            name
        }
    }
`;

export const getClients = gql`
    query getClients{
        clients {
            _id
            name
            phoneNumber
            email
        }}
`
