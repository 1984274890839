import {Address} from "./client";
import {gql} from '@apollo/client';
import {FullClient} from "./fragments/client";

export type LocationInputData = {
  clientId: string;
  area?: string
  name: string;
  email?: string;
  phoneNumber?: string;
  address: Address;
}

export type EditLocationData = {
  area?: string
  name: string;
  email?: string;
  phoneNumber?: string;
  address: Address;
}

export type Location = {
  name: string;
  area?: string;
  email?: string;
  phoneNumber?: string;
  address: Address;
}

export const createLocation = gql`
    mutation createLocation($name: String!, $area: String, $clientId: String!, $email: String, $phoneNumber: String, $address: AddressInput!) {
        createLocation(name: $name, area: $area, clientId: $clientId, email: $email, phoneNumber: $phoneNumber, address: $address) {
            name
            billingAddress {
                address
            }
        }
    }
`;

export const editLocation = gql`
    mutation editLocation($clientId: String!, $name: String!, $locationData: EditLocationInput) {
        editLocation(clientId: $clientId, name: $name, locationData: $locationData) {
            ...FullClient
        }
    }
    ${FullClient}
`;
