import {Button, Col, Form, InputNumber, message, Select, Space} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {Copier as CopierType} from "../../networking/api/copier";
import {
  createMeterReading as createMeterReadingQuery,
  KeyValuePair,
  ReadingInputType
} from "../../networking/api/meter-readings";
import {StyledExitButton, StyledLoadingIndicator} from "../styled-antd";
import {OnCopierEdit} from "../copier/components/copier";
import {useMutation} from "@apollo/client";

const EnterMeterReading = ({copier, dismiss}: { copier: CopierType, dismiss: OnCopierEdit }) => {
  const [createMeterReading] = useMutation(createMeterReadingQuery, {
    update(cache, {data: {createMeterReading}}) {
      cache.modify({
        fields: {
          copiers(currentCopiers: CopierType[]) {
            return currentCopiers.map<CopierType>(copier => {
              if (copier.id === createMeterReading.id) return createMeterReading;
              return copier;
            });
          }
        }
      })
    }
  });
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (values: { readings: KeyValuePair[] }) => {
    const readingData: Omit<ReadingInputType, "invoiced"> = {time: Math.floor(Date.now() / 1000), data: values.readings}
    setLoading(true)
    createMeterReading({variables: {copierId: copier.id, readingData}}).then(_ => {
      message.success("Entered Meter Reading")
      dismiss();
    }).catch(e => {
      // const error  = e.response.errors[0];
      console.log(e);
      message.error("Could not enter reading")
      // if (e.message.includes("MissingValuesError")) {
      //     message.error( "You must include a reading for all rates");
      // } else if (e.message.includes("InvalidDataError")) {
      //     message.error( "All readings must be greater or equal to last reading");
      // } else {
      //     message.error("Could not enter reading")
      // }
    }).finally(() => setLoading(false));
  }

  if (loading) {
    return <StyledLoadingIndicator style={{margin: "20px auto"}}/>
  }

  return (
    <div>
      <StyledExitButton style={{margin: "20px 0 20px auto"}} onClick={dismiss}/>
      <Form onFinish={handleSubmit}>
        <Form.List name="readings">
          {(fields, {add, remove}) => {
            return (
              <Col>
                {fields.map(field => (
                  <Space key={field.key} style={{display: 'flex', marginBottom: 8}} align="start">
                    <Form.Item
                      {...field}
                      name={[field.name, 'name']}
                      fieldKey={[field.fieldKey, 'name']}
                      rules={[{required: true, message: 'Missing reading type name'}]}
                    >
                      <Select style={{width: 200}}
                              options={copier.rates.map(rate => ({label: rate.name, value: rate.name}))}/>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'value']}
                      fieldKey={[field.fieldKey, 'value']}
                      rules={[{required: true, message: 'Missing reading amount'}]}
                    >
                      <InputNumber min={1} step={1}/>
                    </Form.Item>

                    <MinusCircleOutlined
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    block
                  >
                    <PlusOutlined/> Add reading
                  </Button>
                </Form.Item>
              </Col>
            );
          }}
        </Form.List>
        <Button type="primary" htmlType="submit" style={{marginTop: 10}} disabled={loading}>
          Update
        </Button>
      </Form>
    </div>
  )
}

export default EnterMeterReading;