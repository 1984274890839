import {editLocation as editLocationQuery, Location, EditLocationData} from "../../networking/api/location";
import {Button, Descriptions, Form, Input, message} from "antd";
import React from "react";
import {StyledFormItem, StyledLoadingIndicator} from "../styled-antd";
import {Client as ClientType} from "../../networking/api/client";
import {useMutation} from "@apollo/client";

const EditLocationInformation = ({clientId, location, onEdit}: { clientId: string, location: Location, onEdit: VoidFunction }) => {
  const [form] = Form.useForm()
  const [editLocation, {loading}] = useMutation(editLocationQuery, {
    update(cache, {data: {editLocation}}) {
      cache.modify({
        fields: {
          client(currentClient: ClientType | null = null) {
            return editLocation
          }
        }
      })
    }
  });

  const handleEdit = (values: EditLocationData) => {
    editLocation({variables: {clientId, name: location.name, locationData: values}}).then(client => {
      message.success("Edited Location")
      onEdit();
    }).catch(error => {
      console.log(error);
      message.error("Could Not Edit Location")
    })
  }

  if (loading) {
    return (
      <div className='client'>
        <StyledLoadingIndicator style={{margin: "20px auto"}}/>
      </div>
    )
  }

  return (
    <Form form={form} initialValues={location} onFinish={handleEdit}>
      <Descriptions column={3} bordered style={{marginBottom: 20}}>
        <Descriptions.Item label={"Name"} span={3}><StyledFormItem
          name="name"><Input/></StyledFormItem></Descriptions.Item>
        <Descriptions.Item label="Email"><StyledFormItem name="email"><Input/></StyledFormItem></Descriptions.Item>
        <Descriptions.Item label="Phone Number"><StyledFormItem
          name={"phoneNumber"}><Input/></StyledFormItem></Descriptions.Item>
        <Descriptions.Item label="Area"><StyledFormItem name={"area"}><Input/></StyledFormItem></Descriptions.Item>
        <Descriptions.Item span={3} label="Address">
          <StyledFormItem name={["address", "address"]}>
            <Input/>
          </StyledFormItem>
        </Descriptions.Item>
        <Descriptions.Item span={1} label="City">
          <StyledFormItem name={["address", "city"]}>
            <Input/>
          </StyledFormItem>
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Postal">
          <StyledFormItem name={["address", "postalCode"]}>
            <Input/>
          </StyledFormItem>
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Province">
          <StyledFormItem name={["address", "province"]}>
            <Input/>
          </StyledFormItem>
        </Descriptions.Item>
      </Descriptions>
      <Button type="primary" htmlType="submit" style={{marginTop: 10}}>
        Update
      </Button>
    </Form>
  )
}

export default EditLocationInformation;
